import { defineStore } from 'pinia'
import { setRetreaverCampaign, getRetreaverNumber, setEverflow } from '@/js/utils'

export const useHurricaneIanStore = defineStore('hurricaneIan', {
  state: () => ({
    retreaver: {
      retreaver_campaign: '5fe686b3facf90eee0000ab59c5441ab',
      default_number: '+18778151666',
      default_formatted: '(877) 815-1666',
      number: '',
      number_formatted: ''
    }
  }),
  actions: {
    getRetreaver() {
      setRetreaverCampaign(this.retreaver.retreaver_campaign)
      getRetreaverNumber().then(({ number, formatted }) => {
        this.retreaver.number = number
        this.retreaver.number_formatted = formatted
      })
    }
  }
})