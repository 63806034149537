import axios from 'axios'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const internalZipLookup = async (zipCode) => {
  return await axios
    .get(`https://zip.org31415.dev/us/${zipCode}`)
    .then(response => {
      if (typeof response.data === 'object' && 'state' in response.data) {
        return {
          county: response.data.county,
          city: response.data.city,
          stateCode: response.data.state,
          state_long: response.data.state_long,
          zipError: false
        }
      } else {
        return {
          zipError: true
        }
      }
    })
    .catch(e => {
      console.log('zipLookup error:', e)
    })
}

export async function ipqsCheckIfPhoneIsValid(phone) {
  //ipqs phone validation only accepts formatted numbers with country code in front
  const formattedNumber = phone.replace(/[()\-\s]+/g, "")
  const ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/phone'

  const response = {
    valid: false,
    active: false,
    fullValid: false
  }

  try {
    const responseFromIpqs = await axios.post(ipqsUrl, {phone: formattedNumber})
    response.valid = responseFromIpqs.data.valid
    response.fullValid = responseFromIpqs.data.valid || responseFromIpqs.data.fraud_score > 85 || responseFromIpqs.data.line_type === 'Toll Free';
    response.active = responseFromIpqs.data.active
  } catch (err) {
    console.log(err)
    return response
  }

  return response
}

export const ipqsIsEmailInvalid = async (email) => {
  let ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/email'

  return await axios.post(ipqsUrl, {
    email
  })
    .then(response => {
      if (!response.data.valid) {
        return true
      } else if (response.data.disposable) {
        return true
      } else if (response.data.fraud_score > 90) {
        return true
      } else {
        return false
      }
    })
    .catch(e => {
      console.log('error:', e)
    })
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
export const checkForPlaceholder = text => /^{.+}$/g.test((text || '').trim()) ? '' : text

export const extractUrlParams = () => {
  const urlSearchParams = new URLSearchParams(location.search)

  const obj = {}

  urlSearchParams.forEach((value, key) => {
    switch (key) {
      case 's1':
        obj['sub_id1'] = checkForPlaceholder(value) || '1'
        break
      case 'sub2':
        obj['sub_id2'] = checkForPlaceholder(value)
        break
      case 'sub3':
        obj['sub_id3'] = checkForPlaceholder(value)
        break
      case 'sub4':
        obj['sub_id4'] = checkForPlaceholder(value)
        break
      case 'sub5':
        obj['click_id'] = checkForPlaceholder(value) || ''
        break
      default:
        obj[key] = checkForPlaceholder(value)
    }
  })

  if (obj['sub1']) {
    obj['sub_id1'] = obj['sub1'] || '1'
  }

  if (obj['sub_id']) {
    obj['sub_id1'] = obj['sub_id'] || '1'
  }

  if (!obj['sub_id1']) {
    obj['sub_id1'] = '1'
  }

  if (obj['sub5']) {
    obj['click_id'] = obj['sub5']
    delete obj['sub5'];
  }

  return obj;
}

export const setEverflow = (offId) => {
  console.log('enter', offId)
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.EF) {
        const urlParams = new URLSearchParams(location.search)
        EF.click({
          offer_id: EF.urlParameter('oid') || offId,
          affiliate_id: EF.urlParameter('affid') || 1,
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          source_id: EF.urlParameter('source_id'),
          transaction_id: EF.urlParameter('_ef_transaction_id'),
        }).then(tid => {
          if (tid) {
            urlParams.set('_ef_transaction_id', tid)
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
            resolve({ ef_transaction_id : tid })
          }
        })
        clearInterval(interval)
      }
    }, 5)
  })
}

export const getJornayaLeadIdToken = () => {
  return new Promise(res => {
    const i = setInterval(() => {
      const input = document.getElementById("leadid_token")
      let val = ''
      if (input) {
        val = input.value
      }
      if (val) {
        clearInterval(i)
        res(val)
      }
    }, 5)
  })
}

export const getTrustedForm = () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const trustedForm = extractTrustedForm()
      if (trustedForm.trustedFormUrl.length > 0) {
        clearInterval(interval)
        resolve(trustedForm)
      }
    }, 5)
  })
}

export const extractTrustedForm = () => {
  let trustedFormUrl = '';
  let trustedFormId = '';
  if (document.getElementById('xxTrustedFormCertUrl_0')) {
    const tfCertUrl = document.getElementById('xxTrustedFormCertUrl_0').value
    const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
    trustedFormUrl = tfCertUrl;
    trustedFormId = tfParts[1];
  }
  return {
    trustedFormUrl,
    trustedFormId
  }
}

export const getIpAddress = async () => {
  let ip = '';
  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    ip = response.ip
  } catch (e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    ip = response.match(/ip=([^\n]+)/)[1]
  }
  return ip
}

export const getChannel = () => {
  const r = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1][Math.floor(Math.random() * 20)]
  return ['Google', 'Facebook'][r]
}

export const isNameGibberish = (name) => {
  //checking if name has any prefixes or suffixes is highest priority to ignore the name suffix when running name gibberish tests
  name = removePrefixInNameIfExists(name).trim()
  name = removeSuffixInNameIfExists(name).trim()

  //check if name has a special character that connects name ('-') => if name has hyphen, split the str
  //the reason we choose to only remove hyphens is because if we attempt to remove any special character
  //we are defeating the point of checking for name gibberish, if we checked and removed >?!<| etc, we would end up
  //not flagging an incorrect name

  //check if name has a special character that connects name (' ', '-') => if name has hyphen, and replace with empty space string
  name = name.replace(/[-.,]/m, ' ')

  //remove excess white space
  name = name.replace(/\s+/g, ' ').trim()

  const splitName = name.split(' ')

  //remove any initials
  for (let namePart of splitName) {
    if (namePart.length <= 1) {
      splitName.splice(splitName.indexOf(namePart), 1)
    }
  }

  //iterate through array of split names to test individually only if name has hyphen, otherwise proceed with regular check
  return iterateTests(splitName)
}

const regexNameGibberishTest = (name) => {
  // has less than 2 chars
  if (name.length < 2) {
    return true
  }

  // has no vowels
  if (!/[aeiouyāēīōūȳáéíóúýäöü]/i.test(name)) {
    return true
  }

  // has three consecutive chars
  if (/([A-Za-zÀ-ÖØ-öø-ÿ])\1\1+/.test(name)) {
    return true
  }

  // no vowels in 5 chars
  if (/[^aeiouyāēīōūȳáéíóúýäöü]{5}/mi.test(name)) {
    return true
  }
  return false
}

const removePrefixInNameIfExists = (name) => {
  //remove any prefix in name
  const prefixRegex = /^(hr|fr|md|dr|mr|mrs|miss|ms|sir|sr)(\.|\s+)/i
  if (prefixRegex.test(name)) {
    return name.replace(prefixRegex, "").trim()
  }
  return name
}

const removeSuffixInNameIfExists = (name) => {
  //remove suffix in name
  const suffixRegex = /(?:,|-|\s+)(?:i|ii|iii|iv|jr|sr|dds|phd|md|dvm)\.?$/i
  if (suffixRegex.test(name)) {
    return name.replace(suffixRegex, "").trim()
  }
  return name
}

const iterateTests = (nameArray) => {
  for (const name of nameArray) {
    const regexNameGibberish = regexNameGibberishTest(name)
    if (regexNameGibberish) return true
  }
  return false
}

export const setRetreaverCampaign = (campaign_key) => {
  const a=document.createElement("script")
  a.type="text/javascript"
  a.async=!0
  a.defer=!0
  a.src=document.location.protocol+"//dist.routingapi.com/jsapi/v1/retreaver.min.js"
  a.onload=a.onreadystatechange=function(){
    Retreaver.configure({
      host:"api.routingapi.com",
      prefix:"https:"==document.location.protocol?"https":"http"
    });

    (window.RetreaverCampaign = new Retreaver.Campaign({
      campaign_key
    }))

    window.RetreaverCampaign.request_number(function (number) {
      window.RetreaverNumber = number
    })
  };
  (document.getElementsByTagName("head")[0]||document.getElementsByTagName("body")[0]).appendChild(a)
}

export const getRetreaverNumber = async () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.RetreaverNumber) {
        clearInterval(interval)
        resolve({
          number: window.RetreaverNumber.get("number"),
          formatted: window.RetreaverNumber.get("formatted_number")
        })
      }
    })
  })
}