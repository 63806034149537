<script setup>
import { onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'

const props = defineProps({
  showRetreaver: {
    type: Boolean,
    required: false,
    default: true
  },
  showSteps: {
    type: Boolean,
    required: false,
    default: true
  }
})

import { useHurricaneIanStore } from '@/store/hurricaneIan'

const hurricaneIanStore = useHurricaneIanStore()

const { retreaver } = storeToRefs(hurricaneIanStore)

onBeforeMount(() => {
  hurricaneIanStore.getRetreaver()
})
</script>

<template>
  <nav>
    <div class="wrapper">
      <a
        href="/"
        class="logo"
        :class="{'center' : !props.showSteps && !props.showRetreaver}"
      >
        <h1>
          Hurricane<span class="settlement">Ian</span>Settlement
        </h1>
      </a>
      <div class="steps" v-if="props.showSteps">
        <ul>
          <li>Get a FREE case review</li>
          <li>No obligation</li>
          <li>See how much your case is worth</li>
        </ul>
      </div>

      <div class="retreaver" v-if="props.showRetreaver">
        <a
          class="phone-number"
          :href="`tel:${retreaver.number || retreaver.default_number}`"
        >
          {{ retreaver.number_formatted || retreaver.default_formatted }}
        </a>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="sass">
.logo.center
  margin: 0 auto

.steps
  display: flex
  justify-content: center
  align-items: center
  ul
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
    list-style: none
    padding-left: 0
    li
      position: relative
      padding-left: 1.5rem
      color: #0d2e5c
      font-family: "SharpSans Display No1 Bold", Arial, sans-serif
      font-weight: bold
    li:before
      content: ''
      position: absolute
      left: 0
      width: 1.1em
      height: 1.1em
      background: url("../../assets/svg/check.svg")
      filter: invert(12%) sepia(20%) saturate(6679%) hue-rotate(202deg) brightness(98%) contrast(93%)

@media screen and (max-width: 1245px)
  .steps
    ul
      flex-direction: column
      gap: 0
      align-items: flex-start

@media screen and (max-width: 900px)
  nav .steps ul
    display: none

@media screen and (max-width: 590px)
  nav .wrapper a h1
    font-size: 20px

@media screen and (max-width: 530px)
  nav
    .wrapper
      .retreaver
        max-width: 150px

@media screen and (max-width: 450px)
  nav .wrapper
    .retreaver
      font-size: 16px
    a h1
      font-size: 16px

@media screen and (max-width: 400px)
  nav .wrapper a h1
    font-size: 14px

@media screen and (max-width: 375px)
  nav .wrapper
    justify-content: center
    .logo
      display: none
</style>