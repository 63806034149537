<template>
  <div class="footer">
    <div class="footer-container">
      <div class="first-container">
        <div class="footer-nav">
          <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
          <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
        </div>
      </div>
      <div class="second-container">
        <div class="text">
          My Lawsuit Help is a paid advertisement, not an endorsement, recommendation, or referral to any particular law
          firm. We are not a law firm and we do not provide legal advice or evaluate your legal claim. We are a free
          service that connects you with third party law firms to discuss your claims. You are under no obligation to
          engage the services of our participating law firms. My Lawsuit Help relies on your geographic location to
          determine which law firm to connect you with and makes no representation regarding a lawyer’s experience or
          skills.
          <br><br>
          Copyright © 2021 My Lawsuit Help | All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.footer
  background-color: #fff
  z-index: 2
  .footer-container
    max-width: 1200px
    margin: auto
    text-align: center
    .first-container
      border-top: 2px solid #ededed
      display: flex
      justify-content: center
      padding-top: 10px
      .wrapper
        max-width: 246px
        padding: 10px
        margin: 20px
        border-radius: 5px
        img
          max-width: 100%
      .footer-nav
        display: flex
        align-items: center
        .nav-button
          margin: 0 20px
          text-decoration: none
          font-family: Arial, sans-serif
          &:hover
            color: #ea5d08
    .second-container
      .text
        color: #0d2e5c
        font-family: Arial, sans-serif
        font-weight: 400
        margin: 20px auto 0 auto
        padding-bottom: 20px
        font-size: 0.875rem
        line-height: 1.5rem
        max-width: 90%
@media (max-width: 620px)
  .footer .container .first-container
    display: block
    .wrapper
      margin: 20px auto
    .footer-nav
      padding: 0 20px 20px 20px
@media (max-width: 400px)
  .footer .footer-container .first-container
    display: inline-block
    padding-top: 15px
    .wrapper
      margin: auto
    .footer-nav .nav-button
      padding: 10px
</style>
<script setup>
</script>